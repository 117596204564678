<template>
  <div class="group-course" v-if="pageTitle">
    <ShortcutButtons />
    <Breadcrumb>
      <template slot="restPage">
        <el-breadcrumb-item>
          <router-link :to="{ name: 'sessionClassesList' }">
            {{ $t("pageTitle.sessionClassesList") }}
          </router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          {{ pageTitle }}
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <Heading heading="h4" :content="pageTitle">
      <div slot="button">
        <router-link
          v-if="selectClass[0] && roleIsTeacherOrAdmin"
          :to="{
            name: 'sessionClassesEdit',
            params: { id: selectClass[0].id }
          }"
        >
          <el-button type="primary" size="mini">
            <i class="fa fa-edit"></i>
          </el-button>
        </router-link>
        <router-link
          class="ml-2"
          v-if="roleIsTeacherOrAdmin"
          :to="{
            name: 'sessionClassesCreateLog',
            params: { id: $route.params.classId },
            query: { lType: 'MG' }
          }"
        >
          <el-tooltip
            class="item"
            effect="dark"
            content="Add Meet and Greet"
            placement="top"
          >
            <el-button type="primary" size="mini">
              <i class="fas fa-plus" />
            </el-button>
          </el-tooltip>
        </router-link>
      </div>
    </Heading>
    <hr />
    <el-menu
      :default-active="activeSection"
      class="course-menu mb-3 mt-3"
      mode="horizontal"
      @select="showSection"
      background-color="#f3f3f3"
      :active-text-color="themeColor"
    >
      <el-menu-item index="details">
        <i class="el-icon-s-home"></i>
        {{ $t("newCourses.Info") }}
      </el-menu-item>
      <el-menu-item index="students">
        <i class="el-icon-user-solid"></i>
        Students
      </el-menu-item>
      <el-menu-item index="attendance">
        <i class="el-icon-date"></i>
        {{ $t("newCourses.Attendance") }}
      </el-menu-item>
      <el-menu-item index="materials">
        <i class="el-icon-folder-opened"></i>
        {{ $t("newCourses.Materials") }}
      </el-menu-item>
      <el-menu-item index="tests">
        <i class="el-icon-s-data"></i>
        {{ $t("newCourses.Tests") }}
      </el-menu-item>
      <el-menu-item index="homework">
        <i class="el-icon-s-data"></i>
        Practices
      </el-menu-item>
      <el-menu-item index="lessonLogs">
        <i class="el-icon-s-order"></i>
        {{ $t("newCourses.Lesson Logs") }}
      </el-menu-item>
      <el-menu-item index="progressReport">
        <i class="el-icon-date"></i>
        {{ $t("newCourses.Progress Report") }}
      </el-menu-item>
    </el-menu>
    <div v-for="(section, index) in selectClass" :key="index">
      <div v-if="activeSection === 'details'" class="class-info">
        <Heading heading="h4" :content="$t('newCourses.Info')">
          <template slot="button" v-if="selectClass[0] && roleIsTeacherOrAdmin">
            <router-link
              class="ml-2"
              :to="{
                name: 'sessionClassesEdit',
                params: { id: selectClass[0].id }
              }"
            >
              <el-button type="success" size="mini">
                <i class="fas fa-edit" />
              </el-button>
            </router-link>
          </template>
        </Heading>
        <hr />
        <p>
          <b>Teacher:</b>
          {{
            helper.displayMultipleItems(
              section.session_class_teachers.map(
                ({ teacher_user }) => teacher_user.name
              )
            )
          }}
        </p>
        <p>
          <b>Address:</b>
          {{ section.address }}
        </p>
        <p>
          <b>Date:</b>
          <span v-if="section.started_on && section.ended_on">
            {{ section.started_on }} ~ {{ section.ended_on }}
          </span>
          <span v-else>
            N/A
          </span>
        </p>
        <p>
          <b>Info:</b>
          <span v-if="section.teacher_note">{{ section.teacher_note }}</span
          ><span v-else>N/A</span>
        </p>
        <!-- <p v-if="roleIsTeacherOrAdmin">
          <b>Analysis</b>: &nbsp;
          <a
            target="_blank"
            :href="'/session-classes/analysis?class_id=' + section.id"
            ><i class="fas fa-chart-line"></i
          ></a>
        </p> -->
        <p v-if="$store.getters['user/isRole']('super-admin')">
          <b>Note:</b>
          <span v-if="section.notes">{{ section.notes }}</span
          ><span v-else>N/A</span>
        </p>
      </div>
      <template
        v-if="
          $store.getters['user/isRole']('super-admin') &&
            activeSection === 'students' &&
            students &&
            students.length > 0
        "
      >
        <Heading heading="h4" content="Students"></Heading>
        <hr />
        <table class="table table-bordered">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>家長名字+Email</th>
              <th>年級</th>
              <th>學校</th>
              <th>合約</th>
              <th>備註事項</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="student in studentsInfo" :key="student.id">
              <td>
                <b>
                  <router-link
                    :to="{
                      name: 'ProfileForAdmin',
                      params: { id: student.id }
                    }"
                  >
                    {{
                      user.displayName(student.first_name, student.last_name)
                    }}
                  </router-link>
                </b>
              </td>
              <td>
                {{ student.email }}
              </td>
              <td>
                <div v-for="parent in student.parents" :key="parent.id">
                  <b
                    >{{
                      user.displayName(parent.first_name, parent.last_name)
                    }}:
                  </b>
                  {{ parent.email }}
                </div>
              </td>
              <td>
                {{
                  getGradeOnly(
                    student.student_profile.high_school_graduation_year
                  )
                }}
              </td>
              <td>
                {{ student.student_profile.high_school }}
              </td>
              <td>
                <router-link
                  v-if="student.enrolled_session_id"
                  :to="{
                    name: 'EnrollmentDetail',
                    params: {
                      id: student.enrolled_session_id
                    },
                    query: {
                      view: 'contract'
                    }
                  }"
                >
                  <i class="fa fa-eye"></i>
                </router-link>
              </td>
              <td>
                {{ student.student_profile.taken_class_comments }}
              </td>
            </tr>
          </tbody>
        </table>
      </template>
      <template v-if="activeSection === 'attendance'">
        <div v-if="attendancesIsEditMode">
          <Heading heading="h4" content="學生出席資訊" class="mb-4">
            <template v-if="roleIsTeacherOrAdmin">
              <el-button slot="button" type="primary" size="mini" @click="closeAttendancesEditMode">
                <i class="fas fa-chevron-left"></i>
                {{ $t("button.back") }}
              </el-button>
            </template>
          </Heading>
          <EditAttendancesExplain v-if="roleIsTeacherOrAdmin"/>
          <hr />
          <!-- <div>
            <template v-if="roleIsTeacherOrAdmin">
              <el-button @click="closeAttendancesEditMode" type="primary">
                {{ $t("button.back") }}
              </el-button>
              <EditAttendancesExplain />
            </template>
          </div> -->
          <!-- <h2>學生出席資訊</h2>
          <template v-if="roleIsTeacherOrAdmin">
            <el-button @click="closeAttendancesEditMode" type="primary">
              {{ $t("button.back") }}
            </el-button>
            <EditAttendancesExplain />
          </template> -->
        </div>
        <div v-else >
          <div class="d-flex mb-4">
            <div>
              <Heading heading="h4" content="學生出席資訊"></Heading>
            </div>
            <div class="d-flex">
              <template v-if="roleIsTeacherOrAdmin">
                <AddLessonLogForm @getClass="getSessions" />
                <div class="ml-2">
                  <el-tooltip
                    content="編輯出席表"
                    placement="top"
                    popper-class="tooltipColor"
                  >
                    <el-button
                      type="primary"
                      size="mini"
                      @click="openAttendancesEditMode"
                    >
                      <i class="fas fa-edit" />
                      Edit
                    </el-button>
                  </el-tooltip>
                </div>
              </template>
            </div>
          </div>
          <hr />
        </div>
        <EditStudentsAttendances
          v-if="attendancesIsEditMode"
          :classId="$route.params.classId"
          :lessonDates="lessonDates"
          :studentsAttendances="studentsAttendances"
          @setStudentLessonLogAttendances="
            studentLessonLogAttendance =>
              $store.commit(
                'studentAttendance/setStudentLessonLogAttendance',
                studentLessonLogAttendance
              )
          "
          @updateStudentAttendance="
            studentAttendance =>
              $store.dispatch(
                'studentAttendance/updateStudentAttendance',
                studentAttendance
              )
          "
        />
        <StudentsAttendances
          v-else
          :classId="$route.params.classId"
          :lessonDates="lessonDates"
          :studentsAttendances="studentsAttendances"
          :displayAttendancesStatusByText="true"
          :disableLinkToTeachingLog="roleIsTeacherOrAdmin"
        />
      </template>
      <template v-if="activeSection === 'materials'">
        <Heading heading="h4" content="Materials" class="mb-4">
          <router-link
            slot="button"
            v-if="selectClass[0] && roleIsTeacherOrAdmin"
            :to="{
              name: 'sessionClassesEdit',
              params: { id: selectClass[0].id },
              query: { section: 'materials' }
            }"
          >
            <el-button type="primary" size="mini">
              <i class="fa fa-edit"></i>
            </el-button>
          </router-link>
        </Heading>
        <hr />
        <el-card>
          <Materials v-if="false"></Materials>
          <Lessons></Lessons>
        </el-card>
      </template>
      <template v-if="activeSection === 'tests'">
        <Heading heading="h4" content="考試成績" class="mb-4">
          <router-link
            slot="button"
            v-if="selectClass[0] && roleIsTeacherOrAdmin"
            :to="{
              name: 'sessionClassesEdit',
              params: { id: selectClass[0].id },
              query: { edit_test: 1 }
            }"
          >
            <el-button type="primary" size="mini">
              <i class="fa fa-edit"></i>
            </el-button>
          </router-link>
        </Heading>
        <hr />
        <StudentTestScoreTable
          :section="section"
          type="tests"
          @getSessions="getSessions"
          :disableEdit="!roleIsTeacherOrAdmin"
          :displayStudentUserIds="displayStudentUserIds"
        />
      </template>
      <template v-if="activeSection === 'homework'">
        <Heading heading="h4" content="練習成績" class="mb-4">
          <router-link
            slot="button"
            v-if="selectClass[0] && roleIsTeacherOrAdmin"
            :to="{
              name: 'sessionClassesEdit',
              params: { id: selectClass[0].id },
              query: { edit_test: 1 }
            }"
          >
            <el-button type="primary" size="mini">
              <i class="fa fa-edit"></i>
            </el-button>
          </router-link>
        </Heading>
        <hr />
        <StudentTestScoreTable
          :section="section"
          type="homework"
          @getSessions="getSessions"
          :disableEdit="!roleIsTeacherOrAdmin"
          :displayStudentUserIds="displayStudentUserIds"
        />
      </template>
      <template v-if="activeSection === 'lessonLogs'">
        <Heading heading="h4" content="Lesson Logs" class="mb-4">
          <router-link
            slot="button"
            v-if="roleIsTeacherOrAdmin"
            :to="{
              name: 'sessionClassesCreateLog',
              params: { id: $route.params.classId }
            }"
          >
            <el-button type="primary" size="mini">
              <i class="fas fa-plus" />
            </el-button>
          </router-link>
        </Heading>
        <hr />
        <el-row>
          <el-button
            @click="
              () => {
                lessonLogMode = 'list';
              }
            "
            :type="lessonLogMode === 'list' ? 'success' : 'default'"
          >
            清單顯示
          </el-button>
          <el-button
            @click="
              () => {
                lessonLogMode = 'calendar';
              }
            "
            :type="lessonLogMode === 'calendar' ? 'success' : 'default'"
          >
            日曆顯示
          </el-button>
        </el-row>
        <el-calendar
          v-if="lessonLogMode === 'calendar'"
          :range="
            getFirstMondayAndLastSunday(
              courseSession.started_on,
              courseSession.ended_on
            )
          "
        >
          <template slot="dateCell" slot-scope="{ data }">
            <div style="text-align: center" v-if="hasLessonLog(data.day)">
              <template v-if="roleIsTeacherOrAdmin">
                <router-link
                  :to="
                    `/session-class/${Number($route.params.classId)}/log/${
                      getLessonLogByDate(data.day).id
                    }`
                  "
                >
                  {{
                    data.day.split("-")[2] === "01"
                      ? data.day
                      : data.day.split("-")[2]
                  }}
                </router-link>
                <router-link
                  v-if="getLessonLogByDate(data.day).has_mailed"
                  :to="
                    `/session-class/${Number($route.params.classId)}/editLog/${
                      getLessonLogByDate(data.day).id
                    }`
                  "
                >
                  <i class="fas fa-edit action-icon" />
                </router-link>
                <router-link
                  v-else
                  :to="
                    `/session-class/${Number($route.params.classId)}/editLog/${
                      getLessonLogByDate(data.day).id
                    }`
                  "
                >
                  <i class="fas fa-plus action-icon" />
                </router-link>
                <el-tooltip
                  v-if="
                    isPastLesson(data.day) &&
                      getLessonLogByDate(data.day).has_mailed === 0
                  "
                  class="item"
                  popper-class="tooltipWrong"
                  :content="$t('message.pleaseFinishedTheLessonog')"
                  placement="top"
                >
                  <span class="warning-icon">
                    <i class="fas fa-exclamation" />
                  </span>
                </el-tooltip>
              </template>
              <template v-else-if="isRoleParent() || isRoleStudent()">
                <router-link
                  :to="
                    `/session-class/${Number($route.params.classId)}/log/${
                      getLessonLogByDate(data.day).id
                    }/student/${getLessonLogByDate(data.day).student_user_id}`
                  "
                >
                  {{
                    data.day.split("-")[2] === "01"
                      ? data.day
                      : data.day.split("-")[2]
                  }}
                </router-link>
                <span
                  class="attendance"
                  :style="
                    `font-weight: bolder; color: ${
                      attendanceDict[
                        getLessonLogByDate(data.day).attendance_status
                      ].color
                    }`
                  "
                >
                  {{
                    attendanceDict[
                      getLessonLogByDate(data.day).attendance_status
                    ].text
                  }}
                </span>
                <div
                  class="vocabScore"
                  v-if="getLessonLogByDate(data.day).score"
                >
                  {{
                    `Vocab score: ${getLessonLogByDate(data.day).score}/${
                      getLessonLogByDate(data.day).total_score
                    }`
                  }}
                </div>
              </template>
            </div>
            <div style="text-align: center" v-else>
              <span>
                {{
                  data.day.split("-")[2] === "01"
                    ? data.day.slice(5)
                    : data.day.split("-")[2]
                }}
              </span>
            </div>
          </template>
        </el-calendar>
        <el-tabs v-else v-model="currenctLessonTab" @tab-click="() => {}">
          <template v-if="isRoleAdmin()">
            <TeachingLogsForAdmin
              :isPrivateLesson="false"
              :classId="Number($route.params.classId)"
              :teachingLogs="teachingLogs"
              @reloadCustomLesson="getLessonLogs"
            />
          </template>
          <template v-else-if="isRoleTeacher()">
            <el-tab-pane
              :name="String(teacher.teacher_user.id)"
              :key="teacher.id"
              v-for="teacher in selectClass[0].session_class_teachers"
            >
              <template slot="label">
                <i class="fas fa-user-graduate" />
                {{ teacher.teacher_user.name }}
              </template>
              <TeachingLogs
                :isPrivateLesson="false"
                :classId="Number($route.params.classId)"
                :teachingLogs="teachingLogs[teacher.teacher_user.id]"
                :teacherUserId="teacher.teacher_user.id"
                :teacherUserName="teacher.teacher_user.name"
                @reloadCustomLesson="getLessonLogFromPrivateLessonApi"
              />
            </el-tab-pane>
          </template>
          <template v-if="!isRoleAdmin()">
            <el-tab-pane
              :key="student.id"
              v-for="{ student } in students"
              :label="student.name"
              :name="String(student.id)"
            >
              <template slot="label">
                <i class="far fa-user" />
                {{ student.name }}
              </template>
              <LessonLogs
                :isPrivateLesson="false"
                :classId="Number($route.params.classId)"
                :lessonLogs="lessonLogs[student.id]"
                :enrollSessionId="getEnrollSessionId(student.id)"
                @reloadCustomLesson="getLessonLogFromPrivateLessonApi"
              />
            </el-tab-pane>
          </template>
        </el-tabs>
      </template>
      <template v-if="activeSection === 'progressReport'">
        <Heading heading="h4" content="Progress Reports" class="mb-4">
          <router-link
            slot="button"
            v-if="roleIsTeacherOrAdmin"
            :to="{
              name: 'sessionClassesCreateReport',
              params: { classId: $route.params.classId }
            }"
          >
            <el-button type="primary" size="mini">
              <i class="fas fa-plus" />
            </el-button>
          </router-link>
        </Heading>
        <hr />
        <el-alert
          v-if="
            (isRoleAdmin() || isRoleTeacher()) &&
              progressReportsCompletedCount < progressReportsNeededCount
          "
          style="margin: 20px 0px"
          show-icon
          type="warning"
        >
          <template slot="title">
            <span
              v-html="
                $t('message.teacherNeedToWriteReports', {
                  howManyReportNeedToWrite: progressReportsNeededCount,
                  writtenReports: progressReportsCompletedCount
                })
              "
            />
          </template>
        </el-alert>
        <template v-if="roleIsTeacherOrAdmin">
          <div
            :key="teacher.id"
            v-for="teacher in selectClass[0].session_class_teachers"
          >
            <ClassReports
              :isPrivateLesson="false"
              :className="selectClass[0].title"
              :classId="$route.params.classId"
              :reports="classReports[teacher.teacher_user.id]"
              @fetchReport="fetchReport"
            />
          </div>
        </template>
        <el-tabs v-model="currenctReportTab" @tab-click="() => {}" v-else>
          <el-tab-pane
            :key="student.id"
            v-for="{ student } in students"
            :label="student.name"
            :name="String(student.id)"
          >
            <template slot="label">
              <i class="far fa-user" />
              {{ student.name }}
            </template>
            <StudentReports
              :isPrivateLesson="false"
              :classId="$route.params.classId"
              :reports="studentReports[student.id]"
              @fetchReport="fetchReport"
            />
          </el-tab-pane>
        </el-tabs>
      </template>
    </div>
    <!-- <el-dialog
      title="Add Test"
      width="790px"
      :visible.sync="assignTestDialogVisible"
      :before-close="closeAssignTestDialog"
    >
      <el-row :gutter="20">
        <el-col :span="8">
          <el-date-picker
            class="pl-20"
            v-model="assignTest.exam_date"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="選擇考試日期"
          >
          </el-date-picker>
        </el-col>
        <el-col :span="6">
          <span>
            <el-select
              v-model="assignTest.exam.exam_category_id"
              placeholder="選擇考卷類型"
            >
              <el-option :value="2" label="SAT">SAT</el-option>
              <el-option :value="3" label="ACT">ACT</el-option>
              <el-option :value="1" label="TOEFL">TOEFL</el-option>
            </el-select>
          </span>
        </el-col>
        <el-col :span="10">
          <el-select
            filterable
            v-model="assignTest.exam_id"
            placeholder="選擇考卷"
          >
            <el-option
              v-for="(item, index) in sessionsTests"
              :key="index"
              :label="item.title"
              :value="item.exam_id"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeAssignTestDialog">
          {{ $t("message.cancel") }}
        </el-button>
        <el-button type="primary" @click="createAssignTestToClass">
          {{ $t("alert.confirm") }}
        </el-button>
      </span>
    </el-dialog> -->
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import { user } from "@ivy-way/material";
import moment from "moment";
import { helper } from "@ivy-way/material";
import courseApi from "@/apis/course";
import onlineSAT from "@/apis/onlineSAT";
import lessonLogApi from "@/apis/lessonLog";
import privateApi from "@/apis/private";
import reportApi from "@/apis/report";
import testsApi from "@/apis/tests.js";
import roleMixin from "@/mixins/role";
import Breadcrumb from "@/components/Breadcrumb";

//attendance
import StudentsAttendances from "@/components/attendance/StudentsAttendances";
import EditStudentsAttendances from "@/components/attendance/EditStudentsAttendances";
import EditAttendancesExplain from "@/components/attendance/EditAttendancesExplain";
import AddLessonLogForm from "@/views/attendance/AddLessonLogForm";

import TeachingLogs from "@/views/lessonLog/TeachingLogs";
import TeachingLogsForAdmin from "@/views/lessonLog/TeachingLogsForAdmin";
import LessonLogs from "@/views/lessonLog/LessonLogs";
import StudentReports from "@/views/report/StudentReports";
import ClassReports from "@/views/report/ClassReports";
import ShortcutButtons from "@/components/course/ShortcutButtons";
import StudentTestScoreTable from "@/components/sessionClass/StudentTestScoreTable";
import Materials from "@/views/sessionClasses/materials/Index.vue";
import Lessons from "@/views/sessionClasses/materials/Index.vue";
import profileTest from "@/mixins/profileTest";

const initAssignTest = {
  exam_date: "",
  exam_id: null,
  which_week: 0,
  exam: {
    exam_category_id: 2
  },
  is_created: 1
};

export default {
  metaInfo() {
    return {
      title: `${this.pageTitle} - Ivy-Way Academy`
    };
  },

  components: {
    ShortcutButtons,
    Breadcrumb,
    TeachingLogs,
    TeachingLogsForAdmin,
    LessonLogs,
    StudentReports,
    ClassReports,
    StudentTestScoreTable,
    //attendance
    AddLessonLogForm,
    EditStudentsAttendances,
    EditAttendancesExplain,
    StudentsAttendances,
    Materials,
    Lessons
  },

  mixins: [roleMixin, profileTest],

  props: [],
  data() {
    return {
      SATList: [],
      assignTestDialogVisible: false,
      assignTest: {
        ...initAssignTest,
        exam: {
          ...initAssignTest.exam
        }
      },
      sessionsTestsSAT: [],
      sessionsTestsTOEFL: [],
      sessionsTestsACT: [],
      studentsInfo: [],
      attendancesIsEditMode: false,
      lessonLogMode: "list",
      courseSession: null,
      course: null,
      selectClass: [],
      placementList: {},
      course_sessions: [],
      classes: [],
      test_num_arr: [0, 1, 2, 3, 4, 5, 6],
      gradingList: [],
      practices: [],
      editStudent: false,
      teacher: {},
      students: [],
      teachingLogs: [],
      lessonLogs: [],
      classReports: [],
      studentReports: [],
      progressReportsCompletedCount: 0,
      progressReportsNeededCount: 0,
      currenctLessonTab: "teacher",
      currenctReportTab: "teacher",
      activeSection: "details"
    };
  },
  computed: {
    ...mapState("user", ["profile", "token"]),
    user() {
      return user;
    },
    helper() {
      return helper;
    },
    lessonLogsByRole() {
      if (this.isRoleTeacher() || this.isRoleAdmin()) {
        return this.teachingLogsContent;
      } else if (this.isRoleStudent() || this.isRoleParent()) {
        return this.theStudentLessonLog;
      }
      return [];
    },
    sessionsTests() {
      switch (this.assignTest.exam.exam_category_id) {
        case 1:
          return this.sessionsTestsTOEFL;
        case 2:
          return this.sessionsTestsSAT;
        case 3:
          return this.sessionsTestsACT;
        default:
          return [];
      }
    },
    theStudentLessonLog() {
      const userId = this.$store.getters["user/getProfile"].id;
      return this.lessonLogs[userId];
    },
    teachingLogsContent() {
      if (this.isRoleAdmin()) {
        return this.teachingLogs;
      } else {
        return this.teachingLogs[Object.keys(this.teachingLogs)[0]];
      }
    },
    pageTitle() {
      if (this.selectClass.length === 0) return "";
      return this.selectClass[0].title;
    },
    attendanceDict() {
      return {
        1: { text: "P", color: "#3c763d" },
        2: { text: "L", color: "#8a6d3b" },
        3: { text: "A", color: "#d50000" }
      };
    },
    lessonDates() {
      return this.$store.getters["studentAttendance/lessonDates"];
    },
    studentsAttendances() {
      return this.$store.getters["studentAttendance/studentsAttendances"];
    },
    roleIsTeacherOrAdmin() {
      return this.isRoleAdmin() || this.isRoleTeacher();
    },
    roleIsTeacher() {
      return this.isRoleTeacher();
    },
    roleIsAdmin() {
      return this.isRoleAdmin();
    },
    displayStudentUserIds() {
      console.log(this.isRoleStudent());
      if (this.roleIsTeacherOrAdmin) {
        return null;
      } else {
        if (this.isRoleStudent()) {
          console.log(this.$store.getters["user/getProfile"].id);
          return [this.$store.getters["user/getProfile"].id];
        } else if (this.isRoleParent()) {
          return this.students.map(({ student_user_id }) => student_user_id);
        } else {
          return [];
        }
      }
    }
  },
  watch: {
    students() {
      if (
        0 < this.students.length &&
        (this.isRoleParent() || this.isRoleStudent())
      ) {
        this.currenctLessonTab = String(this.students[0].student.id);
        this.currenctReportTab = String(this.students[0].student.id);
      }
    }
  },

  async mounted() {
    await this.getSessions();
    // await this.getOnlineSATTests();
    if (this.$route.query.section) {
      this.activeSection = this.$route.query.section;
    }
  },

  methods: {
    async getOnlineSATTests() {
      let students = [];
      if (this.students && this.students.length > 0) {
        this.students.forEach(student => {
          students.push(student.student_user_id);
        });
        const onlineSATLists = await onlineSAT.getOnlineSATTests({
          user_ids: students,
          exam_id: 60
        });
        this.SATList = onlineSATLists.user_exams;
        this.students.forEach(student => {
          let res = null;
          onlineSATLists.user_exams.forEach(result => {
            if (result.ivy_way_user_id == student.student_user_id) {
              res = result;
            }
          });
          student["SATResult"] = res;
        });
      }
    },
    showSection(index) {
      // this.activeSection = index;
      this.$router.replace({
        query: {
          section: index
        }
      });
    },
    async getAssignTest() {
      await Promise.all([
        courseApi.getAllExams({
          type: "SAT"
        }),
        courseApi.getAllExams({
          type: "TOEFL"
        }),
        courseApi.getAllExams({
          type: "ACT"
        })
      ]).then(values => {
        this.sessionsTestsSAT = values[0].exams;
        this.sessionsTestsTOEFL = values[1].exams;
        this.sessionsTestsACT = values[2].exams;
      });
    },
    openAssignTestDialog() {
      this.assignTestDialogVisible = true;
      this.assignTest.which_week = this.selectClass[0].exams.length;
      this.getAssignTest();
    },
    closeAssignTestDialog() {
      this.assignTestDialogVisible = false;
      this.assignTest = {
        ...initAssignTest,
        exam: {
          ...initAssignTest.exam
        }
      };
    },
    openAttendancesEditMode() {
      this.attendancesIsEditMode = true;
    },
    closeAttendancesEditMode() {
      this.attendancesIsEditMode = false;
    },
    isPastLesson(date) {
      return moment(date).isBefore(moment());
    },
    getFirstMondayAndLastSunday(start, end) {
      let weekOfday = moment(start, "YYYY-MM-DD").format("E");
      const monday = moment(start)
        .subtract(weekOfday - 1, "days")
        .format("YYYY-MM-DD");
      weekOfday = moment(end, "YYYY-MM-DD").format("E");
      const sunday = moment(end)
        .add(7 - weekOfday, "days")
        .format("YYYY-MM-DD");
      return [monday, sunday];
    },
    hasLessonLog(date) {
      return this.lessonLogsByRole.find(
        lessonLog => lessonLog.started_at.split(" ")[0] === date
      )
        ? true
        : false;
    },
    getLessonLogByDate(date) {
      return this.lessonLogsByRole.find(
        lessonLog => lessonLog.started_at.split(" ")[0] === date
      );
    },
    async getSessions() {
      const { session_class } = await courseApi.fetchSessionClass(
        this.$route.params.classId
      );
      console.log(session_class);

      const studentsInfo = await courseApi.getCourseStudentsInfo(
        this.$route.params.classId
      );
      this.studentsInfo = studentsInfo.students;
      const courseSessionRes = await courseApi.fetchSession(
        session_class.course_session_id
      );
      this.courseSession = courseSessionRes.course_session;
      const courseRes = await courseApi.fetchCourse(
        this.courseSession.course_id
      );
      this.course = courseRes.course;

      this.fetchReport();
      this.fetchLessonLogs();
      this.fetchStudentAttandances();

      this.students = session_class.student_class_tickets;

      this.studentsInfo.forEach(student => {
        this.students.forEach(item => {
          if (student.id === item.student.id) {
            student["enrolled_session_id"] = item.enrolled_session_id;
          }
        });
      });
      this.teacher = session_class.teacher_user;

      if (this.isRoleTeacher()) {
        const teachers = session_class.session_class_teachers;
        if (teachers.length > 0) {
          this.currenctLessonTab = String(teachers[0].teacher_user.id);
          this.currenctReportTab = String(teachers[0].teacher_user.id);
        }
      }
      this.selectClass = [session_class];
      console.log(this.selectClass);
    },
    getEnrollSessionId(studentId) {
      return this.students.find(
        student => student.student_user_id === studentId
      ).enrolled_session_id;
    },
    async fetchReport() {
      const {
        class_progress_reports_group_by_user: classReports,
        student_progress_reports_group_by_user: studentReports,
        progress_reports_completed_count,
        progress_reports_needed_count
      } = await reportApi.fetchReports(this.$route.params.classId);
      // if (this.teacher) {
      this.classReports = classReports;
      this.studentReports = studentReports;
      this.progressReportsCompletedCount = progress_reports_completed_count;
      this.progressReportsNeededCount = progress_reports_needed_count;
      // }
    },
    setStudentAttendance(lesson) {
      this.$store.commit("studentAttendance/setClass", lesson);
    },
    async getLessonLogFromPrivateLessonApi() {
      const lesson = await privateApi.getCustomLesson({
        class_id: this.$route.params.classId
      });
      this.setStudentAttendance(lesson);
      this.students = lesson.student_class_tickets;
      this.teacher = lesson.teacher_user;
      if (this.teacher) {
        this.teachingLogs = lesson.teaching_logs_group_by_user;
        this.lessonLogs = lesson.lesson_logs_group_by_user;
      }
    },
    async fetchStudentAttandances() {
      const lesson = await lessonLogApi.fetchStudentAttendances({
        session_class_id: this.$route.params.classId
      });
      this.setStudentAttendance(lesson);
    },

    async fetchLessonLogs() {
      if (this.isRoleAdmin()) {
        console.log("im  admin");
        const teaching_logs = await lessonLogApi.fetchLessonLogByQuery({
          class_id: this.$route.params.classId
        });
        this.teachingLogs = teaching_logs.teaching_logs;
      } else {
        console.log("im not admin");
        const lesson = await privateApi.getCustomLesson({
          class_id: this.$route.params.classId
        });
        this.teachingLogs = lesson.teaching_logs_group_by_user;
        this.lessonLogs = lesson.lesson_logs_group_by_user;
      }
    },
    async createAssignTestToClass() {
      if (this.assignTest.exam_date === "") {
        this.$message.error("請選擇考試日期！");
        return;
      }

      if (this.assignTest.exam_id === null) {
        this.$message.error("請選擇考卷！");
        return;
      }

      try {
        await testsApi.createAssignTestToClass(
          this.$route.params.classId,
          this.assignTest
        );
        this.closeAssignTestDialog();
        this.getSessions();
        this.$message.success(this.$t("message.update_success"));
      } catch (e) {
        this.$message.error(this.$t("message.something_went_wrong"));
      }
    }
  }
};
</script>
<style scoped>
.course-menu {
  margin-bottom: 20px;
}
.separate-line {
  display: none;
}
body /deep/ .el-tooltip__popper.is-dark {
  background: #42a16a;
}
.group-course {
  padding: 20px;
}
.table td {
  padding: 5px;
  vertical-align: middle;
  text-align: center;
}
.table th,
.table td {
  text-align: center;
  border-top: none;
  white-space: nowrap;
}
.add-button {
  display: none;
}
.add-result:hover .add-button {
  display: inline-block;
}

.controlAttendanceButtons {
  display: flex;
}

.controlAttendanceButtons > button {
  margin: 0px 8px;
}

.editIcon {
  font-size: 26px;
}
.class-info p {
  display: flex;
}
.class-info p b {
  display: inline-block;
  width: 5rem;
}
</style>
