import subjectsData from "@/data/subjects.json";
import moment from "moment";
export default {
  methods: {
    getGrade(val) {
      let text = "";
      const today = moment().format();
      const year = today.substring(0, 5);
      const month = today.substring(5, 7);
      let curYear = parseInt(year);
      let curVal = parseInt(val);
      const offset = parseInt(month) < 7 ? 0 : 1;

      curVal -= offset;
      if (curVal < curYear - 4) {
        return this.$t("gradeLevel.graduated", { year: curVal + 4 + offset });
      } else if (curVal >= curYear - 4 && curVal < curYear) {
        return this.$t(`gradeLevel.grade${12 + curYear - curVal}`, {
          year: curVal + 4 + offset
        });
      } else if (curVal >= curYear && curVal <= curYear + 12) {
        return this.$t(`gradeLevel.grade${12 + curYear - curVal}`, {
          year: curVal + offset
        });
      } else {
        // else
        return "";
      }
    },
    getGradeOnly(val) {
      let text = "";
      const today = moment().format();
      const year = today.substring(0, 5);
      const month = today.substring(5, 7);
      let curYear = parseInt(year);
      let curVal = parseInt(val);
      const offset = parseInt(month) < 7 ? 0 : 1;

      curVal -= offset;
      if (curVal < curYear - 4) {
        return this.$t("gradeLevelOnly.graduated", {
          year: curVal + 4 + offset
        });
      } else if (curVal >= curYear - 4 && curVal < curYear) {
        return this.$t(`gradeLevelOnly.grade${12 + curYear - curVal}`, {
          year: curVal + 4 + offset
        });
      } else if (curVal >= curYear && curVal <= curYear + 12) {
        return this.$t(`gradeLevelOnly.grade${12 + curYear - curVal}`, {
          year: curVal + offset
        });
      } else {
        // else
        return "";
      }
    },
    getTeacherGrade(val) {
      const today = moment().format();
      const year = today.substring(0, 5);
      const month = today.substring(5, 7);
      let curYear = parseInt(year);
      let curVal = parseInt(val);
      if (!curVal) return "";
      const offset = parseInt(month) < 7 ? 0 : 1;
      curVal -= offset;

      if (curVal < curYear) {
        return this.$t("gradeLevel.graduated", { year: curVal + offset });
      } else if (curVal >= curYear && curVal < curYear + 4) {
        return this.$t(`gradeLevel.grade${12 + 4 + curYear - curVal}`, {
          year: curVal + offset
        });
      } else if (curVal >= curYear + 4 && curVal < curYear + 4 + 12) {
        return this.$t(`gradeLevel.grade${12 + 4 + curYear - curVal}`, {
          year: curVal - 4 + offset
        });
      } else {
        // else
        return "";
      }
    },
    getTeacherGradeSimple(val) {
      const today = moment().format();
      const year = today.substring(0, 4);
      const month = today.substring(5, 7);
      let curYear = parseInt(year);
      let curVal = parseInt(val);
      if (!curVal) return "";
      const offset = parseInt(month) < 7 ? 0 : 1;
      curVal -= offset;

      if (curVal < curYear) {
        return curVal + offset;
      } else if (curVal >= curYear && curVal < curYear + 4) {
        return `${12 + 4 + curYear - curVal}(${curVal + offset})`;
      } else if (curVal >= curYear + 4 && curVal < curYear + 4 + 12) {
        return `${12 + 4 + curYear - curVal}(${curVal - 4 + offset})`;
      } else {
        return "";
      }
    },
    getTestString(test, profile) {
      if (Object.keys(profile).length === 0) return "";
      try {
        if (test === "psat") {
          let reading = profile.psat.reading_score;
          let grammar = profile.psat.grammar_score;
          let math = profile.psat.math_score;
          let total = parseInt(reading) + parseInt(grammar) + parseInt(math);
          if (!reading && !grammar && !math) return "None";
          else {
            if (!reading) reading = "0";
            if (!grammar) grammar = "0";
            if (!math) math = "0";
            return `${total} (R: ${reading} / G: ${grammar} / M: ${math})`;
          }
        } else if (test === "satStudent") {
          let reading = profile.sat.reading_score;
          let grammar = profile.sat.grammar_score;
          let math = profile.sat.math_score;
          let writing = profile.sat.writing_score;
          let total = profile.sat.score;
          if (!reading && !grammar && !math && !writing) return "None";
          else {
            if (!reading) reading = "0";
            if (!grammar) grammar = "0";
            if (!math) math = "0";
            if (!writing) writing = "0";
            return (
              total +
              " (R:" +
              reading +
              " / G:" +
              grammar +
              " / M:" +
              math +
              " / W:" +
              writing +
              ")"
            );
          }
        } else if (test === "satTeacher") {
          let english = profile.sat.english_score;
          let math = profile.sat.math_score;
          let writing = profile.sat.writing_score;
          let total = profile.sat.score;
          if (!english && !math && !writing) return "None";
          else {
            if (!english) english = "0";
            if (!math) math = "0";
            if (!writing) writing = "0";
            return `${total} (E: ${english} / M: ${math} / W: ${writing})`;
          }
        } else if (test === "act") {
          let english = profile.act.english_score;
          let math = profile.act.math_score;
          let science = profile.act.science_score;
          let reading = profile.act.reading_score;
          let writing = profile.act.writing_score;
          let total = profile.act.score;
          if (!english && !math && !science && !reading && !writing)
            return "None";
          else {
            if (!english) english = "0";
            if (!math) math = "0";
            if (!science) science = "0";
            if (!reading) reading = "0";
            if (!writing) writing = "0";
            return (
              total +
              " (E:" +
              english +
              " / M:" +
              math +
              " / S:" +
              science +
              " / R:" +
              reading +
              ")"
            );
          }
        } else if (test === "toefl") {
          let reading = profile.toefl.reading_score;
          let listening = profile.toefl.listening_score;
          let speaking = profile.toefl.speaking_score;
          let writing = profile.toefl.writing_score;
          let total = profile.toefl.score;
          if (!reading && !listening && !speaking && !writing) return "None";
          else {
            if (!reading) reading = "0";
            if (!listening) listening = "0";
            if (!speaking) speaking = "0";
            if (!writing) writing = "0";
            return (
              total +
              " (R:" +
              reading +
              " / L:" +
              listening +
              " / S:" +
              speaking +
              " / W:" +
              writing +
              ")"
            );
          }
        } else if (test === "ielts") {
          let reading = Number(profile.ielts.reading_score);
          let listening = Number(profile.ielts.listening_score);
          let speaking = Number(profile.ielts.speaking_score);
          let writing = Number(profile.ielts.writing_score);
          let total = Number(profile.ielts.score);
          if (!reading && !listening && !speaking && !writing) return "None";
          else {
            if (!reading) reading = "0";
            if (!listening) listening = "0";
            if (!speaking) speaking = "0";
            if (!writing) writing = "0";
            return (
              total +
              " (R:" +
              reading +
              " / L:" +
              listening +
              " / S:" +
              speaking +
              " / W:" +
              writing +
              ")"
            );
          }
        } else if (test === "ap") {
          let output = [];
          profile.ap.forEach(x => {
            if (x.score !== null) {
              output.push(
                this.getLabelFromData(x.subject).replace("AP ", "") +
                  ": " +
                  x.score
              );
            }
          });
          if (output.length === 0) {
            output.push("None");
          }
          return output;
        } else if (test === "ib") {
          let output = [];
          profile.ib.forEach(x => {
            if (x.score !== null) {
              output.push(
                this.getLabelFromData(x.subject).replace("IB ", "") +
                  ": " +
                  x.score
              );
            }
          });
          if (output.length === 0) {
            output.push("None");
          }
          return output;
        }
      } catch (e) {
        console.warn(`Have no ${test} test`, e);
        return "";
      }
    },
    getLabelFromData(subject) {
      let res = "";
      subjectsData.forEach(x => {
        x.options.forEach(y => {
          if (y.value === subject) {
            res = y.label;
          }
        });
      });
      return res;
    },
    getLabelOfSubjects(subjects) {
      if (!subjects) return [];
      let satSelectedArray = [];
      let actSelectedArray = [];
      let toeflSelectedArray = [];
      let counselingSelectedArray = [];
      let apSelectedArray = [];
      let ibSelectedArray = [];
      let schoolSelectedArray = [];
      let greSelectedArray = [];
      let gmatSelectedArray = [];
      let mcatSelectedArray = [];

      subjects.forEach(x => {
        // Regex
        if (/^sat[^2]/.test(x)) {
          satSelectedArray.push(x);
        } else if (/^act/.test(x)) {
          actSelectedArray.push(x);
        } else if (/^counseling/.test(x)) {
          counselingSelectedArray.push(x);
        } else if (/^toefl/.test(x)) {
          toeflSelectedArray.push(x);
        } else if (/^ap/.test(x)) {
          apSelectedArray.push(x);
        } else if (/^ib/.test(x)) {
          ibSelectedArray.push(x);
        } else if (/^school/.test(x)) {
          schoolSelectedArray.push(x);
        } else if (/^gre/.test(x)) {
          greSelectedArray.push(x);
        } else if (/^gmat/.test(x)) {
          gmatSelectedArray.push(x);
        } else if (/^mcat/.test(x)) {
          mcatSelectedArray.push(x);
        }
      });
      if (satSelectedArray.length === 4) satSelectedArray = ["SAT"];
      if (actSelectedArray.length === 4) actSelectedArray = ["ACT"];
      if (counselingSelectedArray.length === 3)
        counselingSelectedArray = ["Counseling"];
      if (toeflSelectedArray.length === 4) toeflSelectedArray = ["TOEFL"];
      if (apSelectedArray.length === 27) apSelectedArray = ["AP"];
      if (ibSelectedArray.length === 22) ibSelectedArray = ["IB"];
      if (schoolSelectedArray.length === 8) schoolSelectedArray = ["School"];

      const temp = [
        ...satSelectedArray,
        ...actSelectedArray,
        ...toeflSelectedArray,
        ...counselingSelectedArray,
        ...apSelectedArray,
        ...ibSelectedArray,
        ...schoolSelectedArray,
        ...greSelectedArray,
        ...gmatSelectedArray,
        ...mcatSelectedArray
      ];
      let output = [];

      for (let i = 0; i < temp.length; i++) {
        subjectsData.forEach(x => {
          if (x.label === temp[i]) {
            output.push(temp[i]);
          } else {
            x.options.forEach(y => {
              if (y.value === temp[i]) {
                output.push(y.label);
              }
            });
          }
        });
      }

      return output;
    }
  }
};
