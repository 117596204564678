<template>
  <div class="reports">
    <el-table :data="reports" style="width: 100%">
      <el-table-column type="index" label="#" width="50" />
      <el-table-column label="Title">
        <template slot-scope="scope">
          <a :href="`/${routePath}/${classId}/progress-report/${scope.row.id}`">
            {{
              `${timeRange[scope.row.time_range_type]} Report - ${className}[${
                scope.row.from
              } ~ ${scope.row.to}]`
            }}
          </a>
        </template>
      </el-table-column>
      <el-table-column
        width="100"
        :label="isCounseling ? 'Meetings' : 'Lessons'"
        prop="teaching_logs_cnt"
      />
      <el-table-column width="100" label="hour">
        <template slot-scope="scope">
          {{
            scope.row.teaching_logs.length === 0
              ? 0
              : scope.row.teaching_logs.reduce(
                  (totalHour, log) => totalHour + log.full_hour,
                  0
                )
          }}
        </template>
      </el-table-column>
      <el-table-column
        label="Actions"
        v-if="isRoleAdmin() || isRoleTeacher()"
        width="200"
      >
        <template slot-scope="scope">
          <el-tooltip
            class="item"
            content="mail report to teacher"
            placement="top"
            popper-class="tooltipColor"
          >
            <el-button
              @click="
                () => {
                  toMailReportToTeacherPage(scope.row.id);
                }
              "
              class="mr-2"
              type="primary"
              size="mini"
            >
              <i class="el-icon-message" />
            </el-button>
          </el-tooltip>
          <el-tooltip
            class="item"
            content="Edit"
            placement="top"
            popper-class="tooltipColor"
          >
            <router-link
              :to="
                `/${routePath}/${classId}/progress-report/${scope.row.id}/edit`
              "
            >
              <el-button class="mr-2" type="primary" size="mini">
                <i class="el-icon-edit-outline" />
              </el-button>
            </router-link>
          </el-tooltip>
          <DeleteIconButton :onDelete="deleteClassReport(scope.row.id)" />
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import DeleteIconButton from "@/components/button/DeleteIconButton";
import reportApi from "@/apis/report";
import roleMixin from "@/mixins/role";
import { timeRange } from "./dictionarys";

export default {
  props: {
    isPrivateLesson: {
      type: Boolean,
      default: true
    },
    className: {
      type: String,
      default: ""
    },
    classId: {
      type: [String, Number],
      default: ""
    },
    privateType: {
      type: String,
      default: "privateLesson"
    },
    reports: {
      type: Array,
      default: () => []
    }
  },
  components: { DeleteIconButton },
  mixins: [roleMixin],
  computed: {
    isCounseling() {
      return this.privateType === "counseling";
    },
    routePath() {
      if (!this.isCounseling) return "session-class";
      if (this.privateType === "counseling") {
        return "private/counseling";
      }
      return "private/private-lessons";
    },
    timeRange() {
      return timeRange;
    }
  },
  methods: {
    deleteClassReport(classReportId) {
      return async () => {
        await reportApi.deleteClassReport(classReportId);
        this.$emit("fetchReport");
      };
    },
    toMailReportToTeacherPage(class_progress_report_id) {
      this.$router.push(
        "/mail/progress-report?class_progress_report_id=" +
          class_progress_report_id +
          "&target=teacher"
      );
    }
  }
};
</script>

<style lang="scss" scope>
.reports {
  margin: 20px 0px 40px 0px;
}
</style>
