<template>
  <div class="reports">
    <el-table :data="reports" style="width: 100%">
      <el-table-column type="index" label="#" width="50" />
      <el-table-column label="Teacher" width="200">
        <template slot-scope="scope">
          {{ scope.row.class_progress_report.teacher_user.name }}
        </template>
      </el-table-column>
      <el-table-column label="Title">
        <template slot-scope="scope">
          <a
            :href="
              `/${routePath}/${classId}/progress-report/${scope.row.class_progress_report_id}`
            "
          >
            {{
              `${
                timeRange[scope.row.class_progress_report.time_range_type]
              } Report - ${className}[${scope.row.from} ~ ${scope.row.to}]`
            }}
          </a>
        </template>
      </el-table-column>
      <el-table-column
        width="100"
        :label="isCounseling ? 'Meetings' : 'Lessons'"
        prop="lesson_logs_cnt"
      />
      <el-table-column
        width="100"
        label="hour"
      >
        <template slot-scope="scope">
          {{
            scope.row.lesson_logs.length === 0 ? 0 :
            scope.row.lesson_logs.reduce((totalHour, log) => totalHour + log.teaching_log.full_hour, 0)
          }}
        </template>
      </el-table-column>
      <el-table-column label="Actions" v-if="isRoleAdmin()" width="200">
        <template slot-scope="scope">
          <el-tooltip
            class="item"
            effect="dark"
            content="mail report to parents"
            placement="top"
          >
            <i
              class="el-icon-message action-icon"
              @click="
                () => {
                  toMailReportToParentsPage(scope.row.id);
                }
              "
            />
          </el-tooltip>
          <router-link
            :to="
              `/${routePath}/${classId}/progress-report/${scope.row.class_progress_report_id}/edit`
            "
          >
            <i class="el-icon-edit-outline action-icon" />
          </router-link>
          <DeleteIconButton
            :onDelete="deleteStudentClassReport(scope.row.id)"
          />
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import DeleteIconButton from "@/components/button/DeleteIconButton";
import reportApi from "@/apis/report";
import roleMixin from "@/mixins/role";
import { timeRange } from "./dictionarys";

export default {
  props: {
    isPrivateLesson: {
      type: Boolean,
      default: true
    },
    className: {
      type: String,
      default: ""
    },
    classId: {
      type: [String, Number],
      default: ""
    },
    privateType: {
      type: String,
      default: "privateLesson"
    },
    reports: {
      type: Array,
      default: () => []
    }
  },
  components: {
    DeleteIconButton
  },
  mixins: [roleMixin],
  computed: {
    isCounseling() {
      return this.privateType === "counseling";
    },
    routePath() {
      if (!this.isPrivateLesson) return "session-class";
      if (this.isCounseling) {
        return "private/counseling";
      }
      return "private/private-lessons";
    },
    timeRange() {
      return timeRange;
    }
  },
  methods: {
    deleteStudentClassReport(studentReportId) {
      return async () => {
        await reportApi.deleteStudentClassReport(studentReportId);
        this.$emit("fetchReport");
      };
    },
    toMailReportToParentsPage(student_progress_report_id) {
      this.$router.push(
        "/mail/progress-report?student_progress_report_id=" +
          student_progress_report_id +
          "&target=parents"
      );
    },
    toMailReportToStudentPage(student_progress_report_id) {
      this.$router.push(
        "/mail/progress-report?student_progress_report_id=" +
          student_progress_report_id +
          "&target=student"
      );
    }
  }
};
</script>

<style lang="scss" scope>
.reports {
  margin: 20px 0px 40px 0px;
}
</style>
