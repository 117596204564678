<template>
  <div v-if="isRoleAdmin()" class="shortcutButtons">
    <router-link :to="{ name: 'courseIntrosList' }">
      <el-button :type="isCourse ? 'success' : ''">
        {{ $t("pageTitle.courseIntrosList") }}
      </el-button>
    </router-link>
    <router-link :to="{ name: 'courseSessionsList' }">
      <el-button :type="isSession ? 'success' : ''">
        {{ $t("pageTitle.courseSessionsList") }}
      </el-button>
    </router-link>
    <router-link :to="{ name: 'sessionClassesList' }">
      <el-button :type="isClass ? 'success' : ''">
        {{ $t("pageTitle.sessionClassesList") }}
      </el-button>
    </router-link>
  </div>
</template>

<script>
import roleMixin from "@/mixins/role";

export default {
  mixins: [roleMixin],
  computed: {
    isCourse() {
      const coursePageNames = ["courseIntrosList"];
      return coursePageNames.includes(this.$route.name);
    },
    isSession() {
      const sessionPageNames = ["courseSessionsList"];
      return sessionPageNames.includes(this.$route.name);
    },
    isClass() {
      const classPageNames = ["sessionClassesList", "classDetail"];
      return classPageNames.includes(this.$route.name);
    }
  }
};
</script>
<style lang="scss" scoped>
.shortcutButtons {
  margin-top: 20px;
  a {
    margin-right: 8px;
  }
}
</style>
